<template>
  <div class="container" :style="containerStyle">
    <base-spinner class="base-spinner" v-if="loadingStatus === 'loading' && (patientId || !mustSelectPatient)">
    </base-spinner>
    <p v-if="mustSelectPatient && !patientId">{{ tr('No patient is currently selected.') }}</p>
    <p v-else-if="loadingStatus === 'timeout error' || loadingStatus === 'tool error'">{{ tr(loadingStatus) }}</p>
    <iframe v-else ref="iframe" :style="iframeStyle"></iframe>
    <div class="overlay" v-if="isDraggingToolId"></div>
  </div>
</template>

<script>
// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, inject, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'

import BaseSpinner from '@/components/ui/icons/BaseSpinner.vue'
import { themeColors } from '@/common/shared.js'

import { clearIframe, loadTool } from './loadTool'

export default {
  components: {
    BaseSpinner,
  },
  props: {
    isDraggingToolId: {
      type: String,
      required: true,
    },
    payload: {
      type: Object,
      required: true,
    },
    toolId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const MUST_SELECT_PATIENT_PARAMS = ['patientId', 'patientIds']
    const store = useStore()
    const loadingStatus = ref('loading')
    const featureToggles = computed(() => store.getters.featureToggles)
    const tr = inject('tr')

    const iframe = ref(null)

    const currentThemeName = computed(() => store.getters.currentThemeName)
    const language = computed(() => store.getters.profile.language)
    const patientId = computed(() => {
      if (!featureToggles.value.clinicianView) {
        console.log('BlackBoxSSRTool currently only supports clinicianView.')
        return ''
      }
      return store.getters.session.patient.id
    })
    const patientBirthdate = computed(() => {
      if (!featureToggles.value.clinicianView) { return '' }
      return store.getters.session.patient.birthdate
    })
    const patientOrganization = computed(() => {
      if (!featureToggles.value.clinicianView) { return '' }
      return store.getters.session.patient.organization
    })
    const patientFamilyName = computed(() => {
      if (!featureToggles.value.clinicianView) { return '' }
      return store.getters.session.patient.family
    })
    const patientGivenName = computed(() => {
      if (!featureToggles.value.clinicianView) { return '' }
      return store.getters.session.patient.given
    })

    const containerStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].modalBackgroundColor,
        display: loadingStatus.value === 'loading' ? 'flex' : null,
      }
    })
    const iframeStyle = computed(() => {
      return {
        display: loadingStatus.value === 'loading' ? 'none' : null,
      }
    })

    const titleStyle = computed(() => {
      return {
        color: store.getters.isLightMode ? 'black' : 'white',
      }
    })

    const fetchMethod = (payload) => payload.url.method

    const mustSelectPatient = computed(() => {
      for (const param of MUST_SELECT_PATIENT_PARAMS) {
        if (param in props.payload.url.queryParams &&
          props.payload.url.queryParams[param].required) {
          return true
        }
      }
      return false
    })

    const location = computed(() => featureToggles.value.providenceBuild ? 'providence' : 'default')

    const fetchUrl = (payload) => {
      const baseUrl = payload.url.baseUrl
      const queryParams = []
      const queryKeys = Object.keys(payload.url.queryParams)
      let addedToolId = false
      queryKeys.forEach((key) => {
        const info = payload.url.queryParams[key]
        if (key === 'language') {
          queryParams.push(`${info.key}=${encodeURIComponent(language.value)}`)
        } else if (key === 'currentThemeName') {
          queryParams.push(`${info.key}=${encodeURIComponent(currentThemeName.value)}`)
        } else if (key === 'birthdate') {
          queryParams.push(`${info.key}=${encodeURIComponent(patientBirthdate.value)}`)
        } else if (key === 'organization') {
          queryParams.push(`${info.key}=${encodeURIComponent(patientOrganization.value)}`)
        } else if (key === 'family') {
          queryParams.push(`${info.key}=${encodeURIComponent(patientFamilyName.value)}`)
        } else if (key === 'given') {
          queryParams.push(`${info.key}=${encodeURIComponent(patientGivenName.value)}`)
        } else if (key === 'patientId') {
          if (info.required && !patientId.value) {
            return // Do not call tool until a patient is selected.
          }
          if (patientId.value) {
            queryParams.push(`${info.key}=${encodeURIComponent(patientId.value)}`)
          }
        } else if (key === 'patientIds') {
          if (info.required && !patientId.value) {
            return // Do not call tool until a patient is selected.
          }
          if (patientId.value) {
            let idValues = [patientId.value]
            if (patientId.value.includes(',')) {
              // This patient has multiple MRNs. Send all of them.
              // This splits up the CSV into parts and renders the URL as:
              // patient_identifier=mrn1&patient_identifier=mrn2
              idValues = patientId.value.split(',')
            }
            idValues.forEach((val) => {
              queryParams.push(`${info.key}=${encodeURIComponent(val)}`)
            })
          }
        } else if (key === 'toolId') {
          queryParams.push(`${info.key}=${encodeURIComponent(props.toolId)}`)
          addedToolId = true
        } else if (key === 'location') {
          queryParams.push(`${info.key}=${encodeURIComponent(location.value)}`)
        } else {
          const value = payload.data[key]
          if (!info.required && !value) {
            return // Do not add optional undefined parameter.
          }
          queryParams.push(`${info.key}=${encodeURIComponent(value)}`)
        }
      })
      // Always add toolId parameter to prevent browser caching from getting an old (cached) tool response.
      if (!addedToolId) {
        queryParams.push(`toolId=${encodeURIComponent(props.toolId)}`)
      }
      return `${baseUrl}?${queryParams.join('&')}`
    }

    const forceToolReload = (payload) => {
      const url = fetchUrl(payload)
      if (!url) { return }
      loadingStatus.value = 'loading'
      clearIframe(iframe)
      if (mustSelectPatient.value && !(patientId.value)) { return }  // don't attempt to load tool if no patient but must have one.
      const method = fetchMethod(payload)
      const body = payload.data?.body
      loadTool(method, url, body, iframe, loadingStatus, props.toolId)
    }

    watch(currentThemeName, () => {
      if ('currentThemeName' in props.payload.url.queryParams) {
        forceToolReload(props.payload)
      }
    })

    watch(language, () => {
      if ('language' in props.payload.url.queryParams) {
        forceToolReload(props.payload)
      }
    })

    watch(patientId, () => {
      if ('patientId' in props.payload.url.queryParams || 'patientIds' in props.payload.url.queryParams) {
        forceToolReload(props.payload)
      }
    })

    onMounted(() => {
      forceToolReload(props.payload)
      const tool = store.getters.toolById(props.toolId)
      if (tool) {
        tool.onReloadTool = forceToolReload
      }
    })

    return {
      containerStyle,
      iframe,
      iframeStyle,
      loadingStatus,
      mustSelectPatient,
      patientId,
      titleStyle,
      tr,
    }
  },
}
</script>

<style lang="scss" scoped>
.container {
  position: static;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  // This overlay is needed to ensure that drag events do not get "stuck"
  // within the iframe (and thereby not suddenly change the event coordinate
  // system from relative-to-the-top-level-window to relative-to-the-iframe-window).
  // See: https://stackoverflow.com/questions/17051594/prevent-all-js-events-inside-iframe
  div.overlay {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}

.base-spinner {
  margin: auto;
  width: 100px;
  height: 100px;
}

p {
  margin: auto;
  color: red;
  font-size: 1.5rem;
}
</style>
